
<template>
  <div class="wrapper1">
    <brand-nav @search="handleSearch" @add="handleAddBrand"></brand-nav>
    <list :list="brandList" @edit="handleEditItem" @succ="handleSucc"></list>
    <el-pagination
      background
      :hide-on-single-page="true"
      :total="total"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      layout="total, prev, pager, next,sizes"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>
    <edit-brand
      :show="show"
      :type="type"
      :item="item"
      @succ="handleSucc"
    ></edit-brand>
  </div>
</template>

<script>
import BrandNav from "./components/Nav";
import List from "./components/List";
import EditBrand from "./components/EditBrand";
export default {
  name: "PhoneBrand",
  components: { BrandNav, List, EditBrand },
  props: {},
  data() {
    return {
      type: "add",
      show: false,
      pageSizes: [10, 20, 30, 40, 50],
      pageSize: 20,
      page: 1,
      total: 0,
      brandList: [],
      searchText: "",
      id: "",
      item: {},
    };
  },
  created() {
    this.getBrandList();
  },
  methods: {
    //获取品牌列表
    async getBrandList() {
      let data = {
        page: this.page,
        pageSize: this.pageSize,
      };
      if (this.searchText) {
        data.searchText = this.searchText;
      }
      const res = await this.$store.dispatch("getBrandList", data);
      this.brandList = res.data;
      this.total = res.total;
      console.log("12323");
    },
    //搜索
    handleSearch(val) {
      this.searchText = val;
      this.getBrandList();
    },
    //点击弹出新增弹窗
    handleAddBrand() {
      this.show = !this.show;
      this.type = "add";
    },
    //点击弹出编辑弹窗
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
    //提交成功
    handleSucc() {
      this.getBrandList();
    },
    //切换页面数量
    async sizeChange(pageSize) {
      this.pageSize = pageSize;
      this.getBrandList();
    },
    //品牌数据换页
    async currentChange(page) {
      this.page = page;
      this.getBrandList();
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper1 {
  width: 100%;
  font-size: 12px;
  .el-row {
    display: flex;
    align-items: center;
    .el-dropdown {
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 100px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
  .el-table {
    margin-top: 20px;
    /deep/.img {
      width: 60px !important;
      height: 60px !important;
    }
    .red {
      color: #ff0000;
    }
  }
}
</style>
