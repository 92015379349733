<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
      :model="fromData"
      :rules="rules"
      ref="fromData"
    >
      <el-form-item label="品牌名:" prop="name">
        <el-input v-model.trim="fromData.name"></el-input>
      </el-form-item>
      <el-form-item label="简码:" prop="brief_code">
        <el-input v-model.trim="fromData.brief_code"></el-input>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input v-model.number="fromData.sort"></el-input>
      </el-form-item>
      <el-form-item label="商家编码:" prop="mcode">
        <el-input v-model.trim="fromData.mcode"></el-input>
      </el-form-item>
      <el-form-item label="品牌图片">
        <upload-image :src="imageSrc" @getImage="handleGetImage"></upload-image>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
import UploadImage from "@/components/UploadImage";
export default {
  name: "EditBrand",
  components: {
    UploadImage,
  },
  props: {
    type: String,
    show: Boolean,
    item: Object,
  },
  data() {
    return {
      hide: false,
      title: "新增品牌",
      diagonStatus: "",
      labelPosition: "right",
      id: "",
      imageSrc: "", //展示图片
      fromData: {
        id: "",
        name: "",
        mcode: "",
        brief_code: "",
        sort: 1,
        image: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        mcode: [
          { required: true, message: "商家编码不能为空", trigger: "blur" },
        ],
        brief_code: [
          { required: true, message: "品牌简码不能为空", trigger: "blur" },
        ],
        sort: [
          {
            type: "number",
            required: true,
            message: "不能为空并且必须为数字",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增品牌";
        this.fromData = {
          id: "",
          name: "",
          mcode: "",
          brief_code: "",
          sort: 1,
          image: "",
        };
        this.imageSrc = "";
      } else {
        this.title = "编辑品牌";
        this.fromData.id = this.item.id;
        this.fromData.name = this.item.name;
        this.fromData.brief_code = this.item.brief_code;
        this.fromData.mcode = this.item.mcode;
        this.fromData.sort = this.item.sort;
        this.imageSrc = this.item.img_url
          ? this.base_url + this.item.img_url
          : "";
      }
    },
  },
  methods: {
    async handleGetImage(fileList) {
      this.fromData.image = fileList[0];
      this.imageSrc = await this.$tool.fileToBase64(fileList[0]);
    },
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      let fromData = new FormData();
      fromData.append("name", this.fromData.name);
      fromData.append("brief_code", this.fromData.brief_code);
      fromData.append("sort", this.fromData.sort);
      fromData.append("mcode", this.fromData.mcode);
      fromData.append("image", this.fromData.image);
      if (this.type === "add") {
        this.handleAddComfirm(fromData);
        return;
      } else {
        fromData.append("id", this.fromData.id);
        this.handleEditComfirm(fromData);
      }
    },
    //新增提交
    async handleAddComfirm(fromData) {
      const res = await this.$store.dispatch("addBrand", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(fromData) {
      const res = await this.$store.dispatch("editBrand", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>